import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import one from '../images/gallery/1.png'
import two from '../images/gallery/2.png'
import three from '../images/gallery/3.jpg'
import four from '../images/gallery/4.jpg'
import Footer from "../components/footer"
import InspectionsBanner from "../components/inspectionsbanner"
import CallToAction from '../components/calltoaction'
import Coupon from "../components/coupon"

const ResidentialServices = () => (
  <Layout>
    <SEO
        title="Rayburn's Roofing: Residential Services"
        description="Rayburn's Roofing provides top-quality work for homeowners and businesses alike. This is a list of a few of the residential services we offer."
    />
    <InspectionsBanner />
    <div class="max-w-sm w-10/12 lg:max-w-full lg:flex shadow-lg mx-auto mt-10">
        <div class="h-48 lg:h-auto w-48 lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{backgroundImage: `url(${one})`}} title="Rayburn's Roofing offers New Roofs and re-roofs, like the one pictured here">
        </div>
        <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
            <div class="mb-8">
                <div class="text-gray-900 font-bold text-xl mb-2 font-extrabold">New Roofs &amp; Re-roofs</div>
                <p class="text-gray-700 text-base">We specialize in re-roofing and new roofs in Harris, Liberty, Montgomery, and Chambers County. Our experienced team can improve the look of your home, bringing you top quality residential roofing at low prices. Rayburn’s Roofing offers a full range of both residential and commercial roofing products and services in and around the Greater Houston area using only the highest quality materials available from top roofing brands, such as CertainTeed, GAF, and Owens Corning. Our team of contractors have a minimum of 20 years experience, so you can trust Rayburn’s Roofing for the highest quality roofing in the Liberty, Chambers, Harris, and Montgomery County areas.</p>
            </div>
        </div>
    </div>

    <div class="max-w-sm w-10/12 lg:max-w-full lg:flex shadow-lg mx-auto mt-10">
        <div class="border-l border-b border-r border-gray-400 lg:border-r-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-l p-4 flex flex-col justify-between leading-normal">
            <div class="mb-8">
                <div class="text-gray-900 font-bold text-xl mb-2 font-extrabold">Roof Repairs, Maintenance &amp; Inspections</div>
                <p class="text-gray-700 text-base">
                    At Rayburn’s Roofing we assist home owners and building managers in protecting their roofing investment by seeking to identify damage in its early stages. Early detection of leaks and roofing system failures make it possible for us to extend the life of a roofing system through repair or restoration, which is significantly less expensive and time-consuming than re-roofing. Our maintenance programs offer homeowners regularly-scheduled annual or biannual inspections which involve:
                    <ul class="list-disc ml-6">
                        <li>Physical inspection of an existing roofing system to determine its current condition and detect weaknesses and failures.</li>
                        <li>Identify any potential future problems.</li>
                        <li>Cleaning of drains, scuppers, and downspouts.</li>
                        <li>Minor repair to roof areas.</li>
                    </ul>
                </p>
            </div>
        </div>
        <div class="h-48 lg:h-auto w-48 lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-r text-center overflow-hidden" style={{backgroundImage: `url(${four})`}} title="Rayburn's Roofing offers New Roofs and re-roofs, like the one pictured here">
        </div>
    </div>

    <div class="max-w-sm w-10/12 lg:max-w-full lg:flex shadow-lg mx-auto mt-10">
        <div class="h-48 lg:h-auto w-48 lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{backgroundImage: `url(${three})`}} title="Rayburn's Roofing offers New Roofs and re-roofs, like the one pictured here">
        </div>
        <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
            <div class="mb-8">
                <div class="text-gray-900 font-bold text-xl mb-2 font-extrabold">Siding &amp; Gutters</div>
                <p class="text-gray-700 text-base">
                    Upgrading or installing proper siding is the easiest way to not only improve the appearance of your home but also your return on investment. There are a number of options and materials you can use to obtain this appearance: vinyl, cement, composite, aluminum, or stone siding. These materials all offer a distinctive look and feel for your home. In our experience, we've found that most homeowners do not explore the materials used or the various durability or impact resistance before making the correct decision for their house. Contact Rayburn’s Roofing today to discuss the best option for your home. Aluminum seamless gutters are a great way to protect that investment. We install a full range of colors, styles and gutter protection systems to best fit your home. Rayburn’s Roofing uses the highest quality materials available. Because of this, we are able to provide seamless gutter solutions to our customers for professional and highly effective water drainage systems. We care about our customers and our commitment to excellence. Rest assured that we understand that your home is one of the most important investments you will make in your lifetime, and trust us to provide excellent and reliable roofing services for every need in the Greater Houston area.
                </p>
            </div>
        </div>
    </div>

    <div class="max-w-sm w-10/12 lg:max-w-full lg:flex shadow-lg mx-auto mt-10">
        <div class="border-l border-b border-r border-gray-400 lg:border-r-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-l p-4 flex flex-col justify-between leading-normal">
            <div class="mb-8">
                <div class="text-gray-900 font-bold text-xl mb-2 font-extrabold">Storm Damage Restoration &amp; Repairs</div>
                <p class="text-gray-700 text-base">The Greater Houston area is all too familiar with hurricane season. Hail can do serious and immediate damage to your roof. And excessively strong winds may stir up flying debris and knock down branches or entire trees. These projectiles often wreak havoc with shingles, supporting beams, and gutters. Even rain and regular winds can weaken your roof over time. We understand roof and gutter damage – and how to expertly fix it and make your roof look like new again. We offer the best roof repair in Liberty, Harris, Chambers, and Montgomery Counties. When nature strikes, Rayburn’s Roofing is ready. We know that a severely damaged roof can compromise the safety and comfort of your family, so we offer emergency roof repair. We also work with most insurance companies. In addition to free quotes, we also offer Free Storm Damage Inspections. Call Rayburn’s Roofing at (713-865-6014) or through the contact form on our website. Also, ask about our roof maintenance programs to extend the life of your roofing system through regular inspections and repairs.</p>
            </div>
        </div>
        <div class="h-48 lg:h-auto w-48 lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-r text-center overflow-hidden" style={{backgroundImage: `url(${two})`}} title="Rayburn's Roofing offers New Roofs and re-roofs, like the one pictured here">
        </div>
    </div>
    <br />
    <hr />
    <CallToAction />
    <Footer />
    <Coupon />
  </Layout>
)

export default ResidentialServices
